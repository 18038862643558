.select_maincon{
    height: 40px;
    display: flex;
    align-items: center;
    /* border-color: #FF6B00 !important;
    box-shadow: 0 0 0 0.2rem rgba(248, 177, 96, 0.25) !important; */

}
.select_maincon:hover{
    border-color: #FF6B00 !important;
    box-shadow: 0 0 0 0.2rem rgba(248, 177, 96, 0.25) !important; 

}
.p-inputwrapper-focus{
    border-color: #FF6B00 !important;
    box-shadow: 0 0 0 0.2rem rgba(248, 177, 96, 0.25) !important; 
}

