.sTableContainer {
    border-collapse: separate;
    /* border-spacing: 0 0.2px ; */
    width: auto;
    text-align: start;
    align-items: auto;
    border: none !important;
}


.sTableContainer tbody tr td {
    padding: 5px !important;
    font-family: Helvetica !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    color: #121212 !important;

}

.sTableContainer tbody tr th {
    padding: 5px 0 !important;
    font-family: Helvetica !important;
    font-weight: 500 !important;
    line-height: 20px;
    letter-spacing: 0.012em;
    color: #121212 !important;
    width: 50%;
}


.guidelineBox {
    border: 1px solid #DFE1E7;
    border-radius: 8px;
    padding: 6px;
    margin-top: 1rem;
}

.guidelineBox ol li {
    font-size: 14px;
    line-height: 22px;
    color: #535457;
}

.secondContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.tableC {
    width: 50%;
}


.tabContainer {
    position: relative;
}

.tabContainer ul li {
    margin-bottom: 0 !important;
}

.indicators {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 99;
    height: 20px;
    /* width: 400px; */
}

.noRemark {
    color: #B3B7BE;
    font-size: 14px;
}

.sBox {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.activeTab {
    border-bottom: 2px solid #027BFF;
}


.green {
    background: #A0D468;
}

.yellow {
    background: #FFCE54;
}

.blue {
    background: #4FC1E9;
}

.sheetContainer {
    background: rgba(248, 248, 248, 1);

    .top_bar_main_container {
        height: 75px;
        display: flex;
        align-items: center;
        box-shadow: 0px 2px 4px #0000000F;
        background-color: #FFFFFF;
    }

    .main_body_container {
        height: calc(100vh - 75px);
        z-index: -2;
        display: grid;
        grid-template-columns: 1fr 256px;

        .left_container {
            .pdf_container {
                height: calc(100vh - 121px);
            }
        }

        .right_container {
            overflow: auto;

            &::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }



            .sheet_tracker_con {
                border: 1px solid #DFE1E7;
                border-radius: 8px;
                padding: 10px;
            }

            & label {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 6px;
            }

            .marks_con {
                border: 1px solid #DFE1E7;
                border-radius: 8px;
                padding: 8px;
                font-size: 18px;
                font-weight: 500;

                & input {
                    width: 30px;
                    border: none;


                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        margin: 0;
                    }


                }

            }
        }
    }
}

.topbar {
    width: 100%;
    height: 75px;
    position: sticky;
    top: 0;
    padding: 16px 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6);
}

.eTopContainer {
    display: flex;
    justify-content: space-between;
}

.sheets_record_arrow_con {
    position: relative;
}

.test_con {
    position: absolute;
    overflow: hidden;
    top: 40px;
    width: 100%;
    transition: 0.2s ease;

}

.popover_hidden {
    max-height: 0px;
    transition: 0.2s ease;

}

.popover_show {
    max-height: 200px;
    transition: 0.2s ease;

}

.allSheets_num_con {
    position: absolute;
    z-index: 999 !important;
    /* top: 40px; */
    right: 0px;
    width: 400px;
    background-color: white;
    box-shadow: 0px 0px 8px rgb(156, 155, 155);
    border-radius: 5px;
    transition: all 0.2s linear;
    overflow: hidden;
}

.question_container {
    & img {
        max-width: 400px
    }
}


.allSheets_grid_con {
    width: 300px;
    padding: 20px;
    height: 250px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 30px);
    align-items: start;
    gap: 30px;
}

.hidden_popover {
    height: 0px !important;
    padding: 0px !important;
    transition: all 0.2s linear;
    overflow: hidden;
}

.active_sheet {
    font-size: 14px;
    font-weight: 900 !important;
    line-height: 24px;
    letter-spacing: 0.012em;
    border: 2px solid #535457;
}

.num_item {
    /* border: 1px solid red; */
    height: 30px;
    display: grid;
    place-items: center;
    color: #353638;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.012em;

}

.questionCon {
    width: 70%;
}




.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 250px;
}

.ebox {
    width: 16px;
    height: 16px;

}

.question {
    border: 1px solid rgba(179, 183, 190, 1);
    border-radius: 8px;
    padding: 8px 16px;
}

.timing {
    width: 30%;
    padding: 0 0 0 10%;
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 1380px) {
    .indicators {
        top: -20px;
    }
}

@media screen and (max-width: 1000px) {
    .secondContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .btnContainer {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
    }

    .tableC {
        width: 80%;
    }

    .sTableContainer tbody tr th {
        width: 40%;
    }
}

@media screen and (max-width: 880px) {
    .tableC {
        width: 100%;
    }
}


.bottom_slider_con {
    box-sizing: border-box;
    margin-top: 10px;
    background-color: white;
    box-shadow: 0px -4px 40px #00000029;
    position: absolute;
    width: 100%;
    transition: all 0.2s linear;
    bottom: 0px;
    overflow: hidden;
}

.top_bottom_arrow {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.evaluation_action_btn {
    all: unset;
    border-radius: 8px;
    border: 1px solid #DFE1E7;
    padding: 6px 12px;
    text-align: center;
    width: 124px;
    color: #ffffff;
    font-weight: 700;
    background-color: #ED5565;

}

.marks_input {
    height: 60px;
    border-radius: 8px;
    border: 1px solid #DFE1E7;
    width: 80px;
}

.evaluation_ans_btn {
    all: unset;
    border-radius: 8px;
    border: 1px solid #DFE1E7;
    padding: 0px 12px;
    color: #121212;
    font-size: 14px;

}

.pdf_view_btn {
    all: unset;
}