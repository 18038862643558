.live_feed_main_container {
    .icon_btn {
        all: unset;
        border: 1px solid #D9D9D9;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 8px 10px !important;
        cursor: pointer;

    }

    .left_btn {
        border-radius: 8px 0px 0px 8px;
    }

    .right_btn {
        border-radius: 0px 8px 8px 0px;
    }

    .active_btn {
        background-color: #FF6B00;
        color: white;
    }
}

.view_live_feed_container {
    .evaluator_details_con {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #121212;
    }
}

.grid_card_con {
    position: relative;
    ;

    .name {
        position: absolute;
        bottom: 0px;
        left: 0px;
        background-color: #12121299;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        padding: 2px 8px;
    }
}