.main-container {
  width: 100%;
  height: 100vh;
  background: url("/public/images/loginLogo.png");
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
}
.left-contain {
  width: 47%;
}
.logoFixed{
  position: absolute;
  top: 70px;
  left: 60px;
  
}
.eLogo {
  font-family: Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #ffffff;
}
.right-contain {
  width: 53%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-content-div {
  width: 60%;
}
.logIn {
  font-family: Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #FF6B00;
}

.label {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #353638;
}
.form-password {
  position: relative;
}
.password-eye {
  position: absolute;
  top: 63%;
  left: 93%;
}
.forgot-password {
  color: #FF6B00;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
}
.resetPasswordFirst {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: center;
}
.backTologin {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #FF6B00;
}
.password-container{
  width: 100%;
   border-left: 5px solid #34B53A;
  padding: 2px 10px;
  /* margin-right: 70px !important; */
}
@media screen and (max-width: 1800px) {
   .main-container {
    width: 100%;
    height: 100vh;
    background: url("/public/images/loginLogo.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    position: relative;
  } 
  
}
@media screen and (max-width: 1050px) {
  .main-container {
    width: 100%;
    height: 100vh;
    background: url("/public/images/loginLogo.png");
    background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  display: flex;
  }
  .eLogo{
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .left-content {
    display: none;
  }
  .right-contain {
    align-items: center;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .main-container {
    width: 100%;
    height: 100vh;
    background: url("/public/images/loginLogo.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    display: flex;
  }
  .left-content {
    display: none;
    width: 0;
  }
  .right-content-div {
    width: 80%;
  }
  .right-contain {
    align-items: center;
    width: 500%;
    margin-top: 225px;
  }
}

@media screen and (max-width: 375px) {
  .right-contain {
    margin-top: 160px;
  }
}
