.create_question_con {
    .upload_file_con {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 120px;
        border: 3px dashed #747579;
        border-radius: 8px;
        color: #027BFF;
        cursor: pointer;
    }



    .upload_text {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.012em;
        color: #94989E;
    }

    .upload_icon {
        font-size: 40px;
    }

    .question_text_editor {
        height: 300px;
    }

    .question_type_icon {
        background-color: #B3B7BE;
        border-radius: 50%;
        padding: 10px;
    }

    .question_paper_color {
        width: 56px;
        height: 44px;
    }
}

.question_paper_card_con {
    padding: 32px 24px;
    border-radius: 16px;
    border: 1px solid #ECEEF2;
    max-width: 320px;

    .questionPaperCard_heading {
        font-size: 20px;
        font-weight: 500;
        line-height: 19px;
        -webkit-text-stroke: 0.35px #121212 !important;
    }

    .questionPaperCard_text {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #353638;
        letter-spacing: 0em;
        line-height: 17px;
        text-align: left;

    }
}

.question_main_con {
    .main_heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
    }
}

.view_questionPaper_con {
    .upload_file_con {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 200px;
        border: 3px dashed #747579;
        border-radius: 8px;
        color: #027BFF;
        cursor: pointer;
    }

    .upload_icon {
        font-size: 30px;
    }

    .add_more_que {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #FF6B00;
        text-decoration: underline;
        border: none !important;
        background: none !important;
    }
}
.questionListingMainContainer{  
     /* overflow-y: auto;
      max-height: 800px; */
}