.paginationContainer {
    .customPagination {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  
    .customPagination ul {
      display: flex;
      list-style: none;
      justify-content: center;
      gap: 2%;
      padding: 0 !important;
    }
  
    .customPagination ul li {
      padding: 8px 15px;
      border-radius: 8px;
      text-align: center;
      color: rgba(152, 162, 179, 1);
    }
  
    .btn {
      font-size: 14px !important;
      height: 36px !important;
    }
  
    .btn2 {
      font-size: 14px !important;
      height: 36px !important;
      display: none !important;
    }
  
    .customPagination ul li:first-child,
    .customPagination ul li:last-child {
      padding: 0 !important;
      margin: 0;
    }
  
    .customPagination a {
      text-decoration: none !important;
    }
  
    .icon {
      font-size: 14px !important;
    }
  
    .hide {
      display: none;
    }
  
    .show {
      display: block;
    }
  
    @media screen and (max-width: 760px) {
      .btn {
        height: 30px !important;
        font-size: 12px !important;
      }
  
      .btn2 {
        height: 30px !important;
        font-size: 12px !important;
      }
  
      .icon {
        font-size: 12px !important;
      }
  
      .customPagination ul li {
        padding: 5px 10px;
        border-radius: 8px;
        text-align: center;
        color: rgba(152, 162, 179, 1);
      }
    }
  
    @media screen and (max-width: 596px) {
      .btn {
        display: none !important;
      }
  
      .btn2 {
        display: block !important;
      }
  
      .customPagination {
        justify-content: center;
      }
  
      .customPagination ul li {
        padding: 4px 5px;
        font-size: 12px;
      }
  
    }
  }


  .tableContainer {
    .Table_container {
      width: 100%;
      border: 0.5px solid #e2dfdf !important;
      overflow-x: auto;
      background: white;
      /* overflow-y: auto;
      max-height: 1000px; */
      /* border: 1px solid red; */
    }
  
    .roundBorder {
      border-radius: 8px !important;
    }
  
  
    .TableContent {
      width: 100%;
      min-width: 1300px;
      overflow-x: auto !important;
      padding: 0 !important;
      /* -ms-overflow-style: none;
      scrollbar-width: none; */
    }
  
  
  }
  