.answerSheet_main_con {
  .main_heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ff6b00;
  }

  .col_main_container {
    border: 1px solid #dfe1e7;
    border-radius: 16px;
    /* padding: 16px; */
    /* overflow: auto; */
    /* height: calc(100vh - 200px); */
    position: relative;
    max-height: 600px;
    overflow: auto;

  }
  .addScrollbar{
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    padding: 16px;
  }
  .mainAnswerSheetContainer{
    
    /* border: 2px solid red; */
    /* height: 100%; */
    max-height: 800px;
    overflow: auto;
    
    /* overflow-y: auto;
    max-height: 1000px; */
  }

  .table_row {
    padding: 20px 10px;
    border-top: 2px solid #d9d9d9;
    


    .text_1 {
      font-size: 16px;
      font-weight: 500;
      /* font-family: Helvetica; */
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #353638;
    }
  }

  .import_button {
    all: unset;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    border: 1px solid #dfe1e7;
    border-radius: 8px;
    padding: 8px 16px;
  }
  .import_button1 {
    all: unset;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    border: 1px solid #dfe1e7;
    border-radius: 8px;
    padding: 8px 16px;
    background-color: #a1a3a2 !important;
    color: #ffffff !important;
  }
  .no_data_msg {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #747579;
  }

  .folder_name {
    font-size: 16px;
    font-weight: 400;
    color: #353638;
  }

  .folder_name:hover {
    color: #ff6b00;
  }
}

.inport_sheet_con {
  .text_content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #101828;
  }
}

.view_answerSheet_folder_con {
  .heading_1 {
    /* font-family: Helvetica; */
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .heading_2 {
    /* font-family: Helvetica; */
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .error_status {
    background-color: #fcdad780;
    color: #f04438;
    width: fit-content;
    padding: 4px 16px;
    border-radius: 8px;
  }

  .verified_status {
    background-color: #d0f1e180;
    color: #12b76a;
    width: fit-content;
    padding: 4px 16px;
    border-radius: 8px;
  }

  .text_1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.p-tooltip-text {
  background-color: #d8eeff;
  color: black;
}

.p-tooltip-arrow {
  background-color: #d8eeff !important  ;
  color: red !important;
}
.removeScrollBar{
 
}