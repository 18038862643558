.notification-number {
  width: auto;
  height: auto;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f8e2d3;
  color: #FF6B00;
}
  .notificationList{
    border: 1px solid #DFE1E7  ;
    border-radius: 8px;
  }