.textColor {
  -webkit-text-stroke: 0.35px #727272 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: center;
}

.back_btn_con {
  color: #0474ee;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
}
