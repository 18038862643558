.question_paper_card_con_secre1{
    padding: 32px 24px;
    border-radius: 16px;
    border: 1px solid #ECEEF2;
    max-width: 320px;
    .questionPaperCard_heading {
        font-size: 20px;
        font-weight: 500;
        line-height: 19px;
        -webkit-text-stroke: 0.35px #121212 !important;
    }

    .questionPaperCard_text {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #353638;
        letter-spacing: 0em;
        line-height: 17px;
        text-align: left;

    }
}