.view_screenshots_con {
    .active_image {
        border: 2px solid #535457;
        border-radius: 4px;
        overflow: hidden;
    }
}

.viewScreenShots_main_con {

    .screenshot_main_image {
        width: 100%;
        max-width: 600px;
        height: 400px;
    }

    .camera_names_con {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 270px;
        font-weight: 600;

    }
}