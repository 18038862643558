.drawer {
  height: 100vh;
  box-shadow: 9px 0 43px 0 rgba(211, 211, 211, 0.5);
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  /* z-index: 1050; */
}

.drawer2 {
  height: 100vh;
  box-shadow: 9px 0 43px 0 rgba(211, 211, 211, 0.5);
  overflow-y: hidden;
  overflow-x: hidden;
  /* z-index: 1050; */
}

.setting-bottom {
  height: 100px;
}

.custom-nav-dropdown {
  position: relative !important;
}

.main-nav-container {
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-nav-container::-webkit-scrollbar {
  display: none;
}

.fw-5 {
  font-weight: 500 !important;
}

.min-w {
  min-width: 240px !important;
}

.min-w2 {
  min-width: 240px !important;
}

.br-4 {
  border-radius: 4px !important;
}

.hide {
  display: none;
}

.hr {
  width: 100%;
  height: 2px;
  background: #eaecf0 !important;
}

.nav-link.active {
  background: #FF6B00;
  color: #ffffff !important;
}
.primary_popover_item1:hover{
  background: #fac6a3;
  color: #ffffff !important;
} 

.mobile-effect {
  position: absolute;
  background: white;
  z-index: 9999;
  box-shadow: none !important;
  height: 100vh;
}

.bg-tranparen-dark {
  width: 100%;
  height: 100vh !important;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 8050 !important;
}

.content {
  height: 100vh;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: rgba(248, 248, 248, 1);
}
.content2 {
  height: 100vh;
  overflow-y: auto;
  background-color: rgba(248, 248, 248, 1);
}

.app-bar {
  width: 100%;
  height: 75px;
  position: sticky;
  top: 0;
  box-shadow: 0 1px 6px 0 lightgray;
  background: white;
  z-index: 2;
}

.shadow-none {
  box-shadow: none !important;
}

.page-content {
  height: calc(100vh - 76px);
  background-color: rgba(248, 248, 248, 1);
  padding: 2.25rem;
  overflow-y: auto;
}

.logo {
  display: flex;
  padding: 0 8px;
  background: white;
  position: sticky;
  top: 0;
}

.logo1 {
  display: flex;
  padding: 0 12px;
  background: white;
  position: sticky;
  top: 0;
}

ul li {
  margin-bottom: 10px;
}

ul li .nav-link {
  min-height: 40px;
  color: #101828;
  padding: 8px;
  border-radius: 8px;
}

.topbar-p {
  padding: 30px 40px;
}

.childrenContent{
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

.decoration-none {
  text-decoration: none !important;
}

.sub-menu {
  width: 100%;
  overflow: hidden;
  -webkit-transition: all 0.5s ease !important;
  -moz-transition: all 0.5s ease !important;
  -ms-transition: all 0.5s ease !important;
  -o-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important ;
}

.sidenav-width {
  width: 252px;
  transition: 0.5s;
}

.logo-heading {
  font-size: 18px;
  font-weight: 700;
  color: #475467;
}

.p-relative {
  position: relative;
}

.b-none {
  border: 0.1px solid white !important;
}

.accountSetting {
  font-size: 18px;
  font-weight: 700;
  color: #475467;
}
.EvaluteLogo {
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #00436F;
}
@media screen and (max-width: 991px) {
  .page-content {
    padding: 1.75rem;
  }
}

@media screen and (max-width: 500px) {
  .page-content {
    padding: 1rem;
  }
}
