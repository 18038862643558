.back_btn_con {
    color: #FF6B00;
    cursor: pointer;
    width: fit-content;
    text-decoration: none;
}

.okok {
    border: 2px solid red !important;
    display: none;
}

.main_dragAndDrop {
    border-radius: 8px;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;



    .dragDropInner {
        height: 100%;
        background-color: red;
    }

}

.disable_draganddrop {
    color: grey !important;
    border: 3px dashed grey;
}

.enable_draganddrop {
    border: 3px dashed #FF6B00;
    color: #FF6B00;
}

.dragAndDrop_value_filled_con {
    border: 1px solid #747579;
    border-radius: 8px;
    padding: 8px;
    position: relative;

    .delete_icon {
        position: absolute;
        right: 10px;
        top: 5px;
        /* color: #F04438; */
        cursor: pointer;
        ;
        font-size: 20px;

    }
}

.three_dot_btn {
    all: unset;
    font-size: 20px;
}