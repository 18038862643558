body {
  font-family: Helvetica !important;
  letter-spacing: 0.1px !important;
}

h1 {
  font-family: Helvetica !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #121212 !important;
  /* -webkit-text-stroke: 0 !important; */
  letter-spacing: 1.2% !important;
}

.subheadind {
  -webkit-text-stroke: 0.35px #121212 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;

}

.primary_popover_item{
  padding: 10px 20px;

}
.primary_popover_item:hover {
  background-color:#FF6B00 ;
  border-radius: 8px;
  color: white;
}

.pointer {
  cursor: pointer !important;
}

.p-dropdown-panel {
  z-index: 999999 !important;

  .p-dropdown-items {
    padding: 0 !important;

    .p-dropdown-item {
      margin-bottom: 0px !important;
    }
  }
}

.p-overlaypanel {
  &:before {
    content: none;
  }

  &:after {
    content: none;
  }

  margin-top: 0px;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 5px 7px !important;
  /* border: 2px solid red; */
  border-radius: 6px;
  overflow: hidden;
}

.p-datepicker {
  z-index: 9999 !important;
}.p-calendar {
  height: 40px;
  width: 100%;
}

.popover_item {
  cursor: pointer;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.012em;
  color: #535457;
  display: flex;
  align-items: center;
  overflow: hidden;
}


.sub_heading_gray {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  color: #535457;
}

.popover_item:hover {
  background-color: #FF6B00;
  color: #ffffff !important;
}

.HelveticaBold {
  font-family: Helvetica-Bold !important;
  color: #121212;

}

.HelveticaMedium {
  -webkit-text-stroke: 0.35px #353638 !important;
  font-size: 18px !important;
}

.info-box {
  background: #D8EEFF;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
}

.btn-primary {
  background: #FF6B00 !important;
  border: 1px solid #FF6B00 !important;
  color: #FFFFFF !important;
  letter-spacing: 0.5px !important;
  -webkit-text-stroke: 0 !important;
}

.btn-Primary {
  background: #FF6B00 !important;
  border: 1px solid #FF6B00 !important;
  color: #FFFFFF !important;
  letter-spacing: 0.5px !important;
  -webkit-text-stroke: 0 !important;
}
.approved_btn{
  background: #A0D468 !important;
  border: 1px solid #A0D468;
  color: #121212 !important;
}
.unapproved_btn{
  border: 1px solid #DA4453 !important;
  color: #DA4453 !important;
}

.btn-Secondary {
  border: 1px solid #121212 !important;
  background: none !important;
  color: #121212 !important;
  letter-spacing: 0.5px !important;
  -webkit-text-stroke: 0 !important;
}
.btn-markUnapproved{
  color: #94989E !important;
  background: none !important;
  border: 1px solid #DFE1E7 !important;
}

.textDecoration {
  text-decoration: none;
}

.loader_container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.text-main-style {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.012em;
  text-align: left;

}
.textStyle{
  color: #121212;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
}
.textStyleMain{
  -webkit-text-stroke: 0.35px #121212 !important;
  font-size: 16px;
  font-weight: 500;
}

.mainContainer {
  width: 100%;
  padding: 2.5rem;
  height: calc(100vh - 75px);
  /* overflow: hidden; */
  overflow: auto;
  /* height: 100vh; */
  /* border: 2px solid red; */
}

.card {
  border-radius: 16px !important;
  border: none !important;
}

.deletStyle {
  color: #F04438;
  text-decoration: underline;
  cursor: pointer !important;
}

.markAll {
  color: #FF6B00;
  text-decoration: underline;
  cursor: pointer !important;
}
.refreshColor{
  color: #FF6B00;
  cursor: pointer !important;

}

table {
  width: 100%;
  text-align: center;
  align-items: center;
  /* border: 2px solid #ECEEF2; */
  overflow-y: auto;
  /* border: 2px solid blue; */



}

table thead {
  /* border: 2px solid #ECEEF2; */
  padding: 10px;
  border-bottom: 2px solid #F3F5F9;

}

table thead th {
  font-family: Helvetica !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.012em;
  color: #353638 !important;
  padding: 12px;
}

table tbody tr td {
  padding: 20px;
  font-family: Helvetica !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  color: #353638 !important;

}

table tbody tr {
  border-bottom: 2px solid #F3F5F9;

}

.searchInput {
  position: relative;
}

.invalid_input_field {
  border: 1px solid red !important;
  border-radius: 5px;
}

.searchIcon {
  position: absolute;
  top: 12px;
  left: 10px;

}



.oflineColor {
  width: auto;
  height: auto;
  padding: 5px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #FCDAD7;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #F04438;

}

.onlineColor {
  width: auto;
  height: auto;
  padding: 5px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #D0F1E1;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #12B76A;

}

.subHeading {
  /* font-family: Helvetica Medium; */
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: left;
  /* color: #121212; */
  -webkit-text-stroke: 0.35px #121212 !important;

}

.HeadingStyle {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #121212 !important;
  text-decoration: underline !important;


}

.left_align_table thead th {
  text-align: left !important;
  padding-left: 1.4rem;
}

.left_align_table tbody td {
  text-align: left !important;
}

.p-tabview-nav {
  margin: 0 !important;
  padding: 0 !important;
}

.p-tabview-nav-link {
  color: black;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}

.p-tabview-selected .p-tabview-nav-link {
  color: #FF6B00 !important;
}

.p-tabview-panels {
  padding: 0;
}

.activePagination {
  background: #FF6B00;
  border-radius: 8px;
  color: #ffffff !important;
}

.inActive{
  width: 12px;
  height: 12px;
  background: #ED5565;
  border-radius: 50%;
}

.defaultInput{
  border: 1px solid #DFE1E7;
  border-radius: 8px;
  height: 38px;
  min-width: 100%;
  width: fit-content;
  /* text-align: center; */
  align-items: center;
}
.defaultInput1{
  border: 1px solid #DFE1E7;
  border-radius: 8px;
  height: 38px;
  min-width: 100%;
  width: fit-content;
}
.textTransform{
  text-transform: capitalize !important;
}
.wordStyle{
  word-wrap: break-word !important;
}
.fontStyleClass{
  font-family: Helvetica;
font-size: 12px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color:#121212

}

.form-control:focus {
  border-color: #FF6B00 !important;
  box-shadow: 0 0 0 0.2rem rgba(248, 177, 96, 0.25) !important;
}
.colorchange{
  color: green;
}
.unapprovedQuetion{
  color: #F04438;
}
.p-datepicker{
  /* height: 300px !important;
  width: 200px !important; */
  scale: .95 !important;
}
/* .p-calendar .p-datepicker {
height : 200px !important;
} */

.notificationColorHilight{
  background-color: red !important;
  color: #ffffff !important;
}
.cardStyle{
  border-radius: 10px !important;
  border: 2px solid #ECEEF2 !important;
  padding: 20px;
}




.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: white !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #F97B06
  ;
  z-index: 3;
  
}
.p-tabview-nav {
  box-shadow: none !important;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border-bottom: none !important;

}

