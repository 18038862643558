.organizeStep {
  -webkit-text-stroke: 0.35px #535457 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
}

.textColor {
  -webkit-text-stroke: 0.35px #727272 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.012em;
  text-align: center;
}
.addOrganize {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #FF6B00;
  text-decoration: underline;
  cursor: pointer;
}
.addOrganize1{
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #FF6B00;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: #ffffff;
}
